@use 'sass/forward' as *;

// fuentes
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Raleway:wght@700;900&display=swap');

// importar estilo de glide 
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

body {
  font-family: Montserrat;
  position: relative;
 
}

a{
  color: $acento;
  font-weight: bold;
  
}
h1{
  font-size: clamp(3rem, 4vw, 8rem);
}
h2{
  font-size: clamp(2rem, 2vw, 4rem);
}
h1, h2, h3{
  font-family: Raleway;
  color: $acento;
}
.minmax {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
}

.conte {
  width: min(100% - 5rem, 100rem);
  margin-inline: auto;
  @include cel{
    width: min(100% - 2rem, 100rem);
  }
}


