//reset
html {
  box-sizing: border-box;
  font-size: clamp(1rem, 1.1vw, 1.3rem);
  background: #fff;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgb(227, 235, 241) 100%);
  background-repeat: no-repeat;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1 {
  font-weight: 900;
}
h2, h3 {
  font-weight: 700;
}

ol,
ul,li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
a{
  text-decoration: none;
  
}
