.anima {
    -webkit-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
  }
  // animacion de las cards

  // @keyframes cardhover{
  //   from{   
  //     transform: translateX(0%);
  //   }
  //   to{  
  //     transform: translateX(-20%);

  //   }
  // }

  // @keyframes cardhoverSalida{
  //   from{   
  //     transform: translateX(-20%);
  //   }
  //   to{  
  //     transform: translateX(0%);

  //   }
  // }


  // animacion menu
  @keyframes menuAbierto {
   from{
      height:85px;
    }
    to {
      height: 120vh;
    }
  }
  @keyframes menuCerrado {
    from {
      height: 120vh;
    }
    to {
      height: 85px;
    }
  }

  // animacion submenu
  @keyframes submenu{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  .menuFullAbierto {
    animation: menuAbierto 1.2s ease forwards;
    ul, #submenu {
      display: block;
      visibility: visible !important;
      animation: submenu 1.2s ease forwards;
    }
   
    
  }
  
  .desanimaMenu {
    animation: menuCerrado 1s ease backwards;
   
  }