@use "mediaQ" as *;
@use "color" as *;
@use "anima" as *;
// Boton toogle
.menu-toggle {
  position: absolute;
  display: none;
  @include cel {
    display: block;
    z-index: 99000;
  }
  right: 0vh;
  top: 4.6vh;
  z-index: 520;
  width: 30px;
  height: 22px;
  background: transparent;
  border-top: 4px solid;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 4px solid;
  color: $acento;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.menu-toggle:before,
.menu-toggle:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

button.is-active {
  border-color: transparent;
  border-left: none;
}
button.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
button.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

button {
  border: none;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
}

// menu de navegación
.visibleMenu {
  visibility: visible !important;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 900;
  position: relative;

  .logo {
    width: clamp(9rem, 15vw, 12rem);
    padding: 1em;
  }
  // ultimo ul aplicar esta regla
  ul + ul {
    padding: 0 1em;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      padding: 0 1rem;
      position: relative;
    }
  }

  .sport {
    @include cel {
    }
  }
  #submenu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    right: 0;
    bottom: -3rem;
    background: $acento;
    padding: 1rem;
    border-radius: $radius;
    display: flex;
    flex-wrap: wrap;
    a {
      color: #fff;
    }
    @include cel {
      visibility: visible;
      display: none;
      opacity: 0;
      position: relative;
      bottom: auto;
      li {
        padding: 0.8rem 1rem;
      }
    }
  }

  @include cel {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2rem;
    // height: auto!important;
    .logo {
      align-self: flex-start;
    }
    ul:nth-child(3n) {
      border-top: solid 1px $acento;
      li {
        padding: 1em 0;
      }
    }

    ul {
      flex: 1 0 100%;
      visibility: hidden;
      display: none;
      padding: 2em;
    }
  }
}
@mixin boton($flecha) {
  background: $acento;
  color: #fff;
  padding: 0.5rem 2rem;
  display: inline-flex;
  margin: 1rem 0;
  border-radius: $radius;
  @extend .anima!optional;
  
  &:hover{
    background: $azul;
    color: $amarillo;
    box-shadow: 1px 3px 5px -2px rgba(0, 91, 228, 0.73);
    // -webkit-box-shadow: 1px 6px 15px -2px rgba(0, 91, 228, 0.73);
    // -moz-box-shadow: 1px 6px 15px -2px rgba(0, 91, 228, 0.73);
  }

  img {
    margin-right: 0.8rem;
    display: none;
  }
  box-shadow: 1px 6px 15px -2px rgba(0, 91, 228, 0.73);
  -webkit-box-shadow: 1px 6px 15px -2px rgba(0, 91, 228, 0.73);
  -moz-box-shadow: 1px 6px 15px -2px rgba(0, 91, 228, 0.73);

  @if $flecha {
    img {
      width: 20px;
      height: auto;
      display: block;
    }
  }
}

.btn {
  // @include boton($flecha:true);
  @include boton($flecha: false);
}
.btnFlecha {
  // @include boton($flecha:true);
  @include boton($flecha: true);
}
.btnFlechaVacio {
  // @include boton($flecha:true);
  @include boton($flecha: true);
  padding: 0.5rem 1rem;
  img {
    margin-right: 0 !important;
  }
}
.invertido {
  img {
    rotate: 180deg;
  }
}
#hero {
  display: flex;
  flex-wrap: wrap;
  background: url("assets/monatana-fiesch-volken.webp") no-repeat;
  background-size: 65%;
  background-position: 110% top;
  min-height: 70vh;
  padding: 0 2rem;
  @include hd {
    background-position: right bottom;
    background-size: 55%;
  }
  @include cel {
    background-size: 130%;
    background-position: 50% 85%;
    padding: none;
  }

  .izq {
    flex: 1;
    display: inherit;
    justify-content: center;
    align-items: center;
    .bandeja {
      width: 75%;
      p {
        color: $acento;
        padding: 1rem 0;
        span {
          color: #999;
          font-weight: bold;
        }
      }
    }
    @include cel {
      flex: auto;
      .bandeja {
        width: 100%;
        padding: 1rem;
      }
    }
  }
  .der {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, auto auto);
    align-items: center;
    justify-content: center;
    img {
      width: auto;
    }
    @include cel {
      flex: auto;
      padding: 1rem;
    }
    img:nth-child(2) {
      padding-top: 8rem;
    }
    img:nth-child(3) {
      padding-top: 3rem;
    }
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    li {
      padding: 0 0.2rem;
      margin-top: 0.7rem;
      flex: 0 1 15%;
      @include cel {
        flex: 0 1 25%;
        padding: 0 0.5rem;
      }
    }
  }
}

#hero-int {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  min-height: 70vh;
  margin-bottom: $margen-alto;
  align-items: center;
  padding: 0 2rem;

  @include cel {
    padding: 0 0rem;
    margin-bottom: 2rem;
  }

  .izq {
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    background: #fff;
    grid-column: 1 / span 2;
    grid-row: 1;
    border-radius: $radius;
    padding: 3rem 0;
    min-height: 70%;
    box-shadow: 1px 6px 15px -2px rgba(142, 172, 216, 0.73);
    .bandeja {
      width: 75%;
      p {
        color: $acento;
        padding: 1rem 0;
        span {
          color: #999;
          font-weight: bold;
        }
      }
    }
    @include cel {
      grid-column: 1 / -1;
      grid-row: auto;
      order: 2;
      .bandeja {
        width: 85%;
      }
    }
  }
  .der {
    align-items: center;
    justify-content: center;
    grid-column: 2 / -1;
    grid-row: 1;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: $radius;
    }
    @include cel {
      grid-column: 1 / -1;
      grid-row: auto;
      order: 1;
    }
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    li {
      padding: 0 0.2rem;
      margin-top: 0.7rem;
      flex: 0 1 15%;
      @include cel {
        flex: 0 1 25%;
        padding: 0 0.5rem;
      }
    }
  }
}

#hero-team {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  min-height: 70vh;
  margin-bottom: $margen-alto;
  align-items: center;
  padding: 0 2rem;
  gap: 2rem;

  @include cel {
    padding: 0 0rem;
    margin-bottom: 2rem;
  }

  .izq {
    display: flex;
    z-index: 100;
    justify-content: center;
    align-items: center;
    grid-column: 1 / span 2;
    grid-row: 1;
    padding: 3rem 0;
    min-height: 70%;
    .bandeja {
      p {
        color: $acento;
        padding: 1rem 0;
      }
    }
    @include cel {
      grid-column: 1 / -1;
      grid-row: auto;
      text-align: center;
      padding: 0rem 0;
      min-height: 0%;
    }
  }
  .der {
    align-items: center;
    justify-content: center;
    grid-column: 3 / -1;
    grid-row: 1;
    img {
      width: 100%;
      aspect-ratio: 3/2.3;
      object-fit: cover;
      border-radius: $radius;
    }
    @include cel {
      grid-column: 1 / -1;
      grid-row: auto;
    }
  }
}

@mixin contenedor($amarillo) {
  margin: $margen-alto 0;
  overflow: hidden;
  display: flex;
  border-radius: $radius;
  p {
    padding: 1.5rem 0 1rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $radius;
    box-shadow: 1px 6px 15px -2px rgba(142, 172, 216, 0.73);
  }
  .izq {
    flex: 1 0 50%;
    @include cel{
      order: 1;
    }
  }
  .der {
    flex: 1 0 50%;
    display: inherit;
    justify-content: center;
    align-items: center;
    @include cel{
      order: 2;
    }
    .bandeja {
      width: 70%;
    }
  }
  @if $amarillo {
    background: $amarillo-claro;
  } @else {
    background: $azul-claro;
  }
  @include cel {
    flex-wrap: wrap;
    .izq,
    .der {
      flex: 1 0 100%;
    }
    .bandeja {
      margin: $margen-alto 0;
    }
  }
}
.contenedordoble {
  @include contenedor($amarillo: false);
}
.contenedordoble-amarillo {
  @include contenedor($amarillo: true);
}

.section-header {
  text-align: center;
  margin-bottom: $margen-alto;
  p {
    color: $azul;
    padding: 1rem 20%;
    @include cel {
      padding: 1rem 10%;
    }
  }
}

#productos {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;
}
.cardSlider-extras {
  background: $azul-claro;
  grid-auto-columns: 96% !important;
  @extend .anima;
  img {
    cursor: url("assets/cursor-hand-open.png"), grab;
    @include cel {
      scroll-snap-align: start;
    }
    &:last-child {
      object-fit: contain !important;
      background: $azul-claro;
    }
  }
}
.muestrate_btn {
  // aparece el boton en las cartas
  .card_derecha {
    display: block !important;
  }
}
// animaciones disparadas de js
.alhover {
  transform: translateX(-20%);
}

.card-menor {
  flex: 0 1 23% !important;
  @include cel {
    flex: 0 1 100% !important;
  }
}
.card-main {
  flex: 0 1 30%;
  position: relative;
  @include cel {
    flex: 0 1 100%;
  }
  .card_derecha {
    // soy el boton en las cartas con carrusel
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 200;
    display: none;
  }
  .conte-img {
    position: relative;
    aspect-ratio: 7/8;
    overflow: hidden;
    border-radius: $radius;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    @extend .anima;
    &:hover {
      transform: scale(1.1);
    }
    img {
      object-fit: cover;
      aspect-ratio: 6/7.2;
      width: 100%;
      height: 100%;
    }
    .slider {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 102%;

      // img{
      //   @include cel{
      //     scroll-snap-align: start;
      //   }
      // }
    }
    .logo {
      display: flex;
      align-items: center;
      position: absolute;
      padding: 0.5rem;
      background: $amarillo-claro;
      height: 15%;
      aspect-ratio: 2/1.3;
      right: 0;
      bottom: 0;
      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }
  h3 {
    font-weight: bold;
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    padding-top: 1rem;
  }
  p {
    margin: 1rem 0 0.5rem;
  }
}

#sport {
  border-top: solid 1px $acento;
  border-bottom: solid 1px $acento;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 80%;
  padding: 2rem 0;
  margin-top: $margen-alto;
  margin-bottom: $margen-alto;
  @include cel {
    grid-template-columns: 1fr;
    width: 90%;
    .cuadro,
    .cuadroIntro {
      height: 30vh;
    }
  }
  h2 {
    text-shadow: 1px 1px 12px $azul;
    color: #fff;
    @include cel {
      font-size: 1.5rem;
    }
  }

  .cuadroIntro {
    display: flex;
    justify-content: center;
    align-items: center;
    .bandeja {
      width: 60%;
      @include cel {
        width: 80%;
      }
      h2 {
        color: $acento;
        text-shadow: none;
      }
    }
  }
  .cuadro {
    position: relative;
    border-radius: $radius;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
    }
    .cover {
      width: 100%;
    }
    .bandeja {
      position: absolute;
      bottom: 10%;
      left: 10%;
    }
  }
}

.glide {
  padding-left: 2.5rem !important;
  @include cel {
    padding-left: 1rem !important;
  }

  // @include hd {
  //   padding-left: calc((100vw - 100rem) / 2) !important;
  // }

  @media (min-width: 1920px) {
    padding-left: 4.5rem !important;
  }

  .news {
    border-radius: $radius;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    box-shadow: 1px 6px 15px -2px rgba(142, 172, 216, 0.73);
    img {
      aspect-ratio: 6/7;
      object-fit: cover;
      flex: 1 0 100%;
      cursor: url("assets/cursor-hand-open.png"), grab;
    }
    .bandeja {
      h3 {
        font-size: clamp(1.4rem, 1.8vw, 2rem);
        padding-bottom: 1rem;
      }
      display: block;
      flex: 1 0 100%;
      background: $azul-claro;
      padding: 1rem;
      white-space: normal;
    }
  }
}

footer {
  background: $azul;
  text-align: center;
  padding: 5rem 0;
  color: #fff;
  margin-top: $margen-alto;
  img {
    margin-bottom: 2rem;
  }
  h3,
  h4 {
    color: $amarillo;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  p {
    padding: 0 20%;
    line-height: 1.3rem;
    @include cel {
      padding: 0 10%;
    }
  }
  #horario {
    border-top: solid 2px $amarillo;
    margin-top: 3rem;
    h3 {
      // display: inline-block;
      width: fit-content;
      position: relative;
      background-color: $azul;
      padding: 0 1rem;
      top: -1rem;
      left: 50%;
      transform: translatex(-50%);
    }
    .bandeja {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-evenly;

      div {
        flex: 1 0 fit-content;
        @include cel {
          flex: 1 0 100%;
        }
        h4 {
          padding-bottom: 0.5rem;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}

#team{
  position: relative;
  background: $azul;
  color: #fff;
  min-height: 100vh;
  padding: 10% 0;
  display: flex;
  align-items: center;
  transform: translateY(-15%);
  @include cel{
    transform: translateY(-10%);
    padding: 35% 0;
  }
  z-index: -1;
  h2, h3{
    color: #fff;
  }
  p{
    max-width: 60%;
    padding: .5rem 0 3rem;
    @include cel{
      max-width: 100%;
    }
  }
  #members{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    h3{
      font-size: clamp(1.2rem, 1.8vw, 3rem);
      padding: 1rem 0 .3rem;
    }
  }
  .member{
    flex: 0 1 18%;
    margin-bottom: 2rem;
    @include cel{
      flex: 0 1 40%;
    }
    img{
      aspect-ratio: 5/5.2;
      object-fit: cover;
      border-radius: $radius;
    }
  }
}
#history{
  margin-top: -23%;
  @include cel{
    margin-top: -80%;
  }
}